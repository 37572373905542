import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'repairTypeTitle'
})
export class RepairTypeTitlePipe implements PipeTransform {

  transform(value: string): string {
    if (value == 'newRepair') {
      return 'New Repair'
    } else if (value == 'solderingRework') {
      return 'Soldering Rework'
    }
    return 'N/A'
  }

}
