export interface User {
  displayName?: string;
  email: string;
  uid: string;
  userType?: any;
  phone?: number;
  myStore?: any;
  qmrCart?: any;
  emailVerified: boolean;
  streetAddress?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  storeIds?: Array<string>;
  regionIds?: Array<string>;
  permissions?: any;
  disabled?: boolean;
}

export enum userTypes {
  storeUser = 'storeUser',
  admin = 'admin',
  superAdmin = 'superAdmin',
}

export interface adminBuilder {
  userData: {
    email: string;
    userType: userTypes;
    displayName: string;
    regionIds?: Array<string>;
    storeIds?: Array<string>;
    permissions?: any;
    disabled?: boolean;
    phone?: string;
  };
  password: string;
  uid?: string;
}

export interface PermissionsKeys {
  createJob: string;
  createShippingLabel: string;
  createReturnShippingLabel: string;
  linkMultipleJobsToSingleLabel: string;
  addUpdateAfterInitial: string;
  markJobComplete: string;
  createModifyStoreRegions: string;
  configureDestinationLocation: string;
  addFranchiseOwesEachJob: string;
  checkAndUncheckShippingOwed: string;
  createUser: string;
  modifyUserPermissions: string;
  disableUser: string;
  payUnpaid: string;
  createModifyStoreLocation: string;
}

export interface SlackUser {
  id: string;
  team_id: string;
  name: string;
  deleted: boolean;
  color: string;
  real_name: string;
  tz: string;
  tz_label: string;
  tz_offset: number;
  profile: Profile;
  is_admin: boolean;
  is_owner: boolean;
  is_primary_owner: boolean;
  is_restricted: boolean;
  is_ultra_restricted: boolean;
  is_bot: boolean;
  is_app_user: boolean;
  updated: number;
  is_email_confirmed: boolean;
  who_can_share_contact_card: string;
}

export interface Profile {
  title: string;
  phone: string;
  skype: string;
  real_name: string;
  real_name_normalized: string;
  display_name: string;
  display_name_normalized: string;
  status_text: string;
  status_emoji: string;
  status_emoji_display_info: any[];
  status_expiration: number;
  avatar_hash: string;
  always_active: boolean;
  first_name: string;
  last_name: string;
  image_24: string;
  image_32: string;
  image_48: string;
  image_72: string;
  image_192: string;
  image_512: string;
  status_text_canonical: string;
  team: string;
}