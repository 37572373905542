import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { SupportHelpPopupComponent } from '../partials/support-help-popup/support-help-popup.component';
import { AuthService } from '../services/auth/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('popover') popover;
  visibility: string = 'hidden';
  isPopoverOpen = false;
  public popoverComponent;
  constructor(public authService: AuthService, public router: Router, public popoverController: PopoverController) { }

  ngOnInit() { }

  async presentPopover(e: Event) {
    if (this.isPopoverOpen) {
      await this.popoverController.dismiss();
      this.isPopoverOpen = false;
      return;
    }
    this.popoverComponent = await this.popoverController.create({
      component: SupportHelpPopupComponent,
      backdropDismiss: false,
      showBackdrop: false,
      event: e,
      cssClass: "contact-popover",
      size: "auto"
    });

    this.popoverComponent.present();
    this.isPopoverOpen = true;
    const { role } = await this.popoverComponent.onDidDismiss();
    console.log(`Popover dismissed with role: ${role}`)
  }

  async dissmissPopover() {

  }

  async popoverOpen() {
    this.isPopoverOpen = !this.isPopoverOpen;
  }
}
