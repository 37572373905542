/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, Observable, of } from 'rxjs';
import { Charges, Region } from '../models/region.model';
import { ParentService } from './parent.service';
import { map, switchMap } from 'rxjs/operators';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Store, StoreTypes } from '../models/store.model';


@Injectable({
  providedIn: 'root'
})
export class RegionService extends ParentService {

  constructor(
    private afStore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    super();
  }
  allRegionsDocs$(filters?): Observable<Region[]> {
    return this.afStore
      .collection<Region>('regions', ref => {
        let regionRef: any = ref;
        if (filters && filters.hasOwnProperty('active')) {
          regionRef = ref.where('active', '==', filters.active);
        }
        return regionRef;
      })
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          if (filters && filters.hasOwnProperty('regionIds') && filters.regionIds.length > 0) {
            // regionRef = ref.where('docId', 'in', filters.regionIds);
            changes = changes.filter(change => {
              return filters.regionIds.indexOf(change.payload.doc.data().docId) > -1;
            })
          }
          // This will return an observable of an Array of categories
          const regions = changes.map((change) => {
            const region: Region = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return region;
          });
          return regions;
        })
      );
  }
  allRegionsWithStores$(filters?): Observable<Region[]> {
    const joined$ = this.afStore
      .collection<Region>('regions', ref => {
        let regionQuery: any = ref;
        if (filters && filters.hasOwnProperty('active')) {
          regionQuery = regionQuery.where('active', '==', filters.active);
        }
        return regionQuery;
      })
      .valueChanges()
      .pipe(
        switchMap(regions => {
          if (filters.hasOwnProperty('regionIds') && filters.regionIds.length > 0) {
            // regions = regionQuery.where('docId', 'in', filters.regionIds);
            regions = regions.filter(region => {
              return filters.regionIds.indexOf(region.docId) > -1;
            })
          }
          return combineLatest(
            of(regions),
            combineLatest(
              regions.map(region => {
                return this.afStore
                  .collection<Store>('stores', storeRef => {
                    let storeRefQuery: any = storeRef;
                    storeRefQuery = storeRefQuery.where('type', '==', StoreTypes.regionStore);
                    storeRefQuery = storeRefQuery.where('regionId', '==', region.docId);
                    if (filters && filters.hasOwnProperty('active')) {
                      storeRefQuery = storeRefQuery.where('active', '==', 1);
                    }
                    return storeRefQuery;
                  })
                  .valueChanges()
                  .pipe(map(stores => {
                    if (filters.hasOwnProperty('storeIds') && filters.storeIds.length > 0) {
                      // storeRefQuery = storeRefQuery.where('docId', 'in', filters.storeIds);
                      stores = stores.filter(store => {
                        return filters.storeIds.indexOf(store.docId) > -1;
                      })
                    }
                    region.stores = stores;
                    return region;
                  }));
              }
              )
            )
          );
        }),
        map(([regions]) => {
          return regions;
        })
      );
    return joined$;
  }

  async create(postValues) {
    // Prep the new user data for the server
    const serverPayload: Region = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('createRegion');
    return buildAdminCallable(serverPayload);
  }
  assignCharges(postValues) {
    // Prep the new user data for the server
    const serverPayload: Region = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('regionCharges');
    return buildAdminCallable(serverPayload);
  }
  async update(postValues) {
    // Prep the new user data for the server
    const serverPayload: Region = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('regionUpdateQ');
    return buildAdminCallable(serverPayload);
  }
  getCollection$(regionId): Observable<Region> {
    return this.afStore
      .collection<Region>('regions').doc(`${regionId}`).valueChanges();
  }
  // Charges
  chargesDocs$(filters?): Observable<Charges[]> {
    return this.afStore
      .collection<Charges>('regionCharges', ref => {
        let chargesQuery: any = ref;
        if (filters && filters.hasOwnProperty('status')) {
          chargesQuery = chargesQuery.where('status', '==', filters.status);
        }
        if (filters && filters.hasOwnProperty('regionId')) {
          chargesQuery = chargesQuery.where('regionId', '==', filters.regionId);
        }
        chargesQuery = chargesQuery.orderBy('createdAt' , 'desc');
        return chargesQuery;
      })
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          if (filters && filters.hasOwnProperty('regionIds')) {
            // chargesQuery = chargesQuery.where('regionId', 'in', filters.regionIds);
            changes = changes.filter(change => {
              return filters.regionIds.indexOf(change.payload.doc.data().regionId) > -1;
            })
          }
          // This will return an observable of an Array of categories
          const chargess = changes.map((change) => {
            const charge: Charges = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return charge;
          });
          return chargess;
        })
      );
  }
  deleteCharge(postValues) {
    // Prep the new user data for the server
    const serverPayload: Region = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('chargesDelete');
    return buildAdminCallable(serverPayload);
  }
}

