import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReplacePipe } from 'src/app/pipes/replace.pipe';
import { TruncatePipe } from 'src/app/pipes/truncate.pipe';
import { ShippmentTrackingInfoComponent } from 'src/app/partials/shippment-tracking-info/shippment-tracking-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RepairTypeTitlePipe } from 'src/app/pipes/repair-type-title.pipe';
import { UserSelectModalComponent } from 'src/app/partials/modals/user-select-modal/user-select-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImeiDeviceInfoComponent } from 'src/app/partials/imei-device-info/imei-device-info.component';

@NgModule({
  declarations: [ReplacePipe, TruncatePipe, ShippmentTrackingInfoComponent, RepairTypeTitlePipe, UserSelectModalComponent, ImeiDeviceInfoComponent],
  imports: [
    CommonModule,
    IonicModule,
    NgSelectModule
  ],
  exports: [ReplacePipe, TruncatePipe, ShippmentTrackingInfoComponent, RepairTypeTitlePipe, UserSelectModalComponent, ImeiDeviceInfoComponent]
})
export class SharedModule { }
