/* eslint-disable @angular-eslint/use-lifecycle-interface */
import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { StampsTracking } from 'src/app/models/stamps.tracking.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shippment-tracking-info',
  templateUrl: './shippment-tracking-info.component.html',
  styleUrls: ['./shippment-tracking-info.component.scss'],
})
export class ShippmentTrackingInfoComponent implements OnInit {
  env = environment;
  @Input() intialTracking: StampsTracking;
  statusBarLoaded = false;
  e = window.setInterval(this.checkStatusOfStatusBar.bind(this), 1500);
  addLoadingTime = true;
  constructor(
    public navController: NavController,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.addLoadingTime = false;
    }, 200);
    if (this.intialTracking) {
      this.intialTracking.tracking_events = this.intialTracking.tracking_events.reverse();
    }
  }

  ngAfterContentInit() {
    // window.onload = () => {
    //   window.addEventListener('resize', this.setUpPositions.bind(this));
    //   window.setTimeout(this.setUpPositions.bind(this), 1500);
    // };
    let observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting === true) {
        this.setUpPositions();
      }
    });

    // setTimeout(() => {
    //   observer.observe(document.querySelector('#fillbar-for-ng'));
    // }, 1500);
  }
  checkStatusOfStatusBar() {
    if (!this.statusBarLoaded) {
      try {
        this.setUpPositions();
      } catch { }
    } else {
      window.clearInterval(this.e);
    }
  }
  setUpPositions() {
    try {
      const pending = document
        .getElementById('pending2')
        .getBoundingClientRect();
      const completed = document
        .getElementById('completed2')
        .getBoundingClientRect();
      const paid = document
        .getElementById('paid2')
        .getBoundingClientRect();
      const received = document
        .getElementById('received2')
        .getBoundingClientRect();
      const shipped = document
        .getElementById('shipped2')
        .getBoundingClientRect();
      const pendingReturn = document
        .getElementById('pendingReturn2')
        .getBoundingClientRect();
      const readyToShip = document
        .getElementById('readyToShip2')
        .getBoundingClientRect();
      const isMobile = window.innerWidth < 432 ? true : false;

      const pendingPos = isMobile
        ? pending.top + pending.height
        : pending.left + pending.width;
      const completedPos = isMobile
        ? completed.top + completed.height
        : completed.left + completed.width;
      const paidPos = isMobile
        ? paid.top + paid.height
        : paid.left + paid.width;
      const receivedPos = isMobile
        ? received.top + received.height
        : received.left + received.width;
      const shippedPos = isMobile
        ? shipped.top + shipped.height
        : shipped.left + shipped.width;
      const pendingReturnPos = isMobile
        ? pendingReturn.top + pendingReturn.height
        : pendingReturn.left + pendingReturn.width;

      const readyToShipPos = isMobile
        ? readyToShip.top + readyToShip.height
        : readyToShip.left + readyToShip.width;
      const fill = document.querySelector('.fill');
      const bar = document.querySelector('.fillBar').getBoundingClientRect();
      let offset = isMobile ? bar.top - 10 : bar.left - 10;
      switch (fill.id) {
        case 'pending':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${pendingPos - offset}px;`
          );
          break;
        case 'completed':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${completedPos - offset}px;`
          );
          break;
        case 'unrepairable':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${completedPos - offset}px;`
          );
          break;
        case 'received':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${receivedPos - offset}px;`
          );
          break;
        case 'Pending Return':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${pendingReturnPos - offset}px;`
          );
          break;
        case 'Ready to Ship':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${readyToShipPos - offset}px;`
          );
          break;
        case 'shipped':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${shippedPos - offset}px;`
          );
          break;
        case 'paid':
          fill.setAttribute(
            'style',
            `${isMobile ? 'height' : 'width'}: ${paidPos - offset}px;`
          );
          break;
        case 'delivered':
          fill.setAttribute('style', `${isMobile ? 'height' : 'width'}: 100%;`);
          break;
        default:
          // document.querySelector('.fill').style.width = '0px';
          break;
      }
      this.statusBarLoaded = true;
    } catch (err) {
      this.statusBarLoaded = false;
    }
  }
  jobTrackingStatus() {
  }

}
