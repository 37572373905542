import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { Observable } from 'rxjs';
import { Region } from 'src/app/models/region.model';
import { SlackUser, User } from 'src/app/models/user.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CatalogService } from 'src/app/services/catalog.service';
import { CountriesListService } from 'src/app/services/countries-list.service';
import { ParentService } from 'src/app/services/parent.service';
import { RegionService } from 'src/app/services/region.service';
import { StoreService } from 'src/app/services/store.service';
import { SupportTicketService } from 'src/app/services/support-ticket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support-help-popup',
  templateUrl: './support-help-popup.component.html',
  styleUrls: ['./support-help-popup.component.scss'],
})
export class SupportHelpPopupComponent implements OnInit {
  supportConfigForm: FormGroup;
  public slackUsers: SlackUser[] = [];

  topics = [
    "Q500 Issue",
    "Appointment System Issue",
    "Slack Issue",
    "RepairQ Issue",
    "Card Processing/Square Issue",
    "Add New Staff Member",
    "Remove a Staff Member",
    "Other"
  ];
  constructor(
    private formBuilder: FormBuilder,
    public regionService: RegionService,
    public catalogService: CatalogService,
    public storeService: StoreService,
    public supportTicketService: SupportTicketService,
    public parentService: ParentService,
    public alertService: AlertService
  ) {
    this.supportConfigForm = this.formBuilder.group({
      helpTopic: new FormControl(null, [Validators.required]),
      helpDescription: new FormControl('', [Validators.required]),
      assistances: new FormControl([], [Validators.required]),
    });
  }

  ngOnInit() {
    this.storeService.slackUsers().subscribe((userRes) => {
      if (userRes.code == 0) {
        this.slackUsers = userRes.users;
      }
    });
  }

  async submitForm() {
    if (this.supportConfigForm.valid) {
      const postParams = this.supportConfigForm.value;
      this.parentService.initilaLoader(); 
      await this.supportTicketService.create(postParams).subscribe(
        (res) => {
          this.parentService.dismissLoading();
          this.alertService.presentAlert('Success', "Thanks for Reaching Out! <br> A team member will be in touch soon");
          this.supportConfigForm.reset()
          console.log(res)
        },
        (error) => {
          this.parentService.dismissLoading();
          this.alertService.presentAlert('Error', 'Something going wrong');
        }
      );
    }
  }
}
