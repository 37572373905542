// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  emulator: false,
  version: "v1.6",
  firebase: {
    apiKey: 'AIzaSyBlHjHBdqKkjoOBBC_kP2qpT-o_gJVihn8',
    authDomain: 'ngrp-dev.firebaseapp.com',
    projectId: 'ngrp-dev',
    storageBucket: 'ngrp-dev.appspot.com',
    messagingSenderId: '135956054286',
    appId: '1:135956054286:web:08cad6f301b0b81ca3d75f',
    measurementId: 'G-HHF3Y08V39'
  },
  stmapsConfig: {
    baseUrl: 'https://signin.testing.stampsendicia.com/',
    clientId: 'LunO5okkHn6aP1Ohn3dJPTv8ORkSupnH',
    redirectUrl: 'https://us-central1-ngrp-dev.cloudfunctions.net/oauthCallBack',
    environment: 'sandbox'
  },
  squareConfig: {
    // locationId: 'L27N9G6THX043',
    // appId: 'sandbox-sq0idb-sMCXg4rGTyvXeE-jl1t5TQ',   // LOCAL ENV
    locationId: 'LB4QWK9ZRR418',
    appId: 'sandbox-sq0idb-jKUbRPhi031j2bQezOh4Sw',
    environment: 'square-sandbox'
  },
  storageBasePath: 'QMRQ500_Assets/',
  production: false,
  userPermissionsKeys: {
    createJob: 'createJob',
    createShippingLabel: 'createShippingLabel',
    createReturnShippingLabel: 'createReturnShippingLabel',
    linkMultipleJobsToSingleLabel: 'linkMultipleJobsToSingleLabel',
    addUpdateAfterInitial: 'addUpdateAfterInitial',
    markJobComplete: 'markJobComplete',
    createModifyStoreRegions: 'createModifyStoreRegions',
    configureDestinationLocation: 'configureDestinationLocation',
    addFranchiseOwesEachJob: 'addFranchiseOwesEachJob',
    checkAndUncheckShippingOwed: 'checkAndUncheckShippingOwed',
    createUser: 'createUser',
    modifyUserPermissions: 'modifyUserPermissions',
    disableUser: 'disableUser',
    payUnpaid: 'payUnpaid',
    createModifyStoreLocation: 'createModifyStoreLocation',
    placeStoreOrders: 'placeStoreOrders',
    manageOrders: 'manageOrders',
    addUpdateCategory: 'addUpdateCategory',
    addUpdateProduct: 'addUpdateProduct',
    packageReceived: 'packageReceived',
    viewAllShipments: 'viewAllShipments',
    createBuybackQuotes: 'createBuybackQuotes',
    configureBuybackQuotes: 'configureBuybackQuotes',
    createCustomLabels: 'createCustomLabels',
    answerSupportRequests: 'answerSupportRequests'
  },
  permissionDeniedMessage: 'Permission Denied',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
