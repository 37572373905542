import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-front-header',
  templateUrl: './front-header.component.html',
  styleUrls: ['./front-header.component.scss'],
})
export class FrontHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
