import { Component, ElementRef, HostListener, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  @ViewChild('productbtn', { read: ElementRef }) productbtn: ElementRef;
  dropdown = false;
  navBarCalLeft = 0;
  date = new Date()
  togglerAreaShow = false;
  public screenWidth = window.innerWidth;
  env = environment;
  user: User;
  mobileNavOpen = false;
  screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';
  constructor(public authService: AuthService, public router: Router, private ngZone: NgZone) {
    this.authService.user$.subscribe(user => {
      this.user = user;
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.navBarCalLeft = ((window.innerWidth / 2) - 200);
  }

  ngOnInit() {
    this.navBarCalLeft = ((window.innerWidth / 2) - 200);
    window.onresize = () => {
      this.screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';
      if (window.innerWidth > 1020) {
        this.mobileNavOpen = false;
      }
    };
  }
  toggleMobileNav = () => {
    this.mobileNavOpen = !this.mobileNavOpen;
  };
  signOut() {
    this.user = null;
    this.mobileNavOpen = false;
    this.authService.signOut();
  }
  ngZoneRouterLink(path) {
    this.mobileNavOpen = false;
    this.ngZone.run(() => {
      this.router.navigate(path);
    });
  }
  hideDropdown(event) {
    const xTouch = event.clientX;
    const yTouch = event.clientY;

    const rect = this.productbtn.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;

    if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
      this.dropdown = false;
    }
  }
  checkQmrStorePermission() {
    if (this.user?.userType == 'superAdmin' || (this.user?.userType == 'storeUser' && this.user?.permissions?.includes(this.env.userPermissionsKeys.placeStoreOrders))) {
      return true;
    }
    if (this.user?.userType == 'superAdmin' || (this.user?.userType == 'storeUser' && (this.user?.permissions?.includes(this.env.userPermissionsKeys.manageOrders) || this.user?.permissions?.includes(this.env.userPermissionsKeys.placeStoreOrders)))) {
      return true
    }
    return false
  }
  checkConfigurationPermission() {
    if (this.user?.permissions?.includes(this.env.userPermissionsKeys.createModifyStoreRegions)) {
      return true
    }
    if (this.user?.permissions?.includes(this.env.userPermissionsKeys.createModifyStoreLocation)) {
      return true
    }
    if (this.user?.permissions?.includes(this.env.userPermissionsKeys.configureDestinationLocation)) {
      return true
    }
    if (this.user?.permissions?.includes(this.env.userPermissionsKeys.createUser)) {
      return true
    }
    if (this.user?.permissions?.includes(this.env.userPermissionsKeys.configureBuybackQuotes)) {
      return true
    }
    return false;
  }
}
