import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-admin-left-sidebar',
  templateUrl: './admin-left-sidebar.component.html',
  styleUrls: ['./admin-left-sidebar.component.scss'],
})
export class AdminLeftSidebarComponent implements OnInit {

  constructor(public authService: AuthService , public router: Router) { }

  ngOnInit() {}

}
