import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-imei-device-info',
  templateUrl: './imei-device-info.component.html',
  styleUrls: ['./imei-device-info.component.scss'],
})
export class ImeiDeviceInfoComponent implements OnInit {
  @Input() checkerTypeId: string;
  @Input() imeiInfo: any;
  constructor() { }

  ngOnInit() { }

}
