import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { provideAuth, getAuth, connectAuthEmulator } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { providePerformance, getPerformance } from '@angular/fire/performance';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { AuthService } from './services/auth/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { AdminLeftSidebarComponent } from './partials/admin-left-sidebar/admin-left-sidebar.component';
import { AdminHeaderComponent } from './partials/admin-header/admin-header.component';
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import { IonicSelectableModule } from 'ionic-selectable';
import { connectStorageEmulator } from 'firebase/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { NgxPaginationModule } from 'ngx-pagination';
import { TruncatePipe } from './truncate.pipe';
import { AdminComponent } from './admin/admin.component';
import { FrontComponent } from './front/front.component';
import { FrontHeaderComponent } from './partials/front-header/front-header.component';
import { SharedModule } from './admin/shared/shared.module';
import { SupportHelpPopupComponent } from './partials/support-help-popup/support-help-popup.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [AppComponent, AdminLeftSidebarComponent, AdminHeaderComponent, TruncatePipe, AdminComponent, FrontComponent, FrontHeaderComponent, SupportHelpPopupComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.emulator) {
        connectAuthEmulator(auth, 'http://localhost:9099');
        const storage = getStorage();
        connectStorageEmulator(storage, 'localhost', 9199);
        firebase.initializeApp(environment.firebase);
        (firebase.firestore()).settings({
          host: 'localhost:8102',  // port number for the firestore
          ssl: false
        });
        console.log('Firestore Emulator connected');
      }
      return auth;
    }),
    provideFirestore(() => getFirestore()),
    providePerformance(() => getPerformance()),
    provideStorage(() => getStorage()),
    AngularFireFunctionsModule,
    IonicSelectableModule,
    // AngularFireStorageModule
    NgxIonicImageViewerModule,
    // ImageViewerModule.forRoot()
    NgxPaginationModule,
    NgSelectModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ScreenTrackingService,
    UserTrackingService,
    AuthService,
    { provide: USE_EMULATOR, useValue: environment.emulator ? ['localhost', 5001] : undefined },
  ],
  bootstrap: [AppComponent],
  exports: [SharedModule]
})
export class AppModule {
}

