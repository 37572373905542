import { Region } from './region.model';
import { SlackUser } from './user.model';

export interface Store {
  displayName: string;
  repName: string;
  phoneNumber: string;
  streetAddress1: string;
  streetAddress2: string;
  imageUrl: string;
  imageName: string;
  zipCode: string;
  city: string;
  state: string;
  regionId: string;
  docId: string;
  active: number;
  region: Region;
  type: StoreTypes;
  country: any;
  isRequireShippingLabel: boolean;
  isSolderingExemptCharges?: boolean;
  stateOb?: State;
  jobCreatedSlackNotification?: SlackUser[];
  jobDeliverySlackNotification?: string;
  jobReceivedSlackNotification?: SlackUser[];
  jobCompletedUnRepairableSlackNotification?: SlackUser[];
  preDefinedSlackNotification?: SlackUser[];
}

export enum StoreTypes {
  destinationStore = 'destination',
  regionStore = 'regionStore'
}

export interface State {
  label: string;
  value: string;
}