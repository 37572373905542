import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Ticket, TicketStatus } from '../models/ticket.model';
import { leftJoinDocument } from '../operations/collection-join';
import { ParentService } from './parent.service';

@Injectable({
  providedIn: 'root'
})
export class SupportTicketService extends ParentService {

  constructor(
    private afStore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    super();
  }

  create(postValues) {
    // Prep the new user data for the server
    const serverPayload: Ticket = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('createSupportTicket');
    return buildAdminCallable(serverPayload);
  }

  allTicketsDocs$(filters?): Observable<any> {
    return this.afStore
      .collection<Ticket>('qmr-support-tickets', ref => {
        let orderRef: any = ref;
        orderRef = orderRef.orderBy('createdAt', 'desc');
        // if (filters && filters.type) {
        //   orderRef = orderRef.where('type', '==', filters.type);
        // }
        // if (filters && filters.hasOwnProperty('active')) {
        //   storeRef = ref.where('active', '==', filters.active);
        // }
        if (filters && filters.hasOwnProperty('storeIds') && filters.storeIds.length > 0) {
          orderRef = orderRef.where('storeId', 'in', filters.storeIds);
        }
        if (filters && filters.hasOwnProperty('uid') && filters.uid != '') {
          orderRef = orderRef.where('uid', '==', filters.uid);
        }
        return orderRef;
      })
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const tickets = changes.map((change) => {
            const ticket: Ticket = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return ticket;
          });
          return tickets;
        }),
        leftJoinDocument(this.afStore, 'uid', 'users', 'uid')
      );
  }
  getCollection$(ticketId): Observable<Ticket> {
    const job = this.afStore
      .collection<Ticket>('qmr-support-tickets').doc(`${ticketId}`).valueChanges().pipe();
    return job;
  }
  changeTicketStatus(docId, status: TicketStatus, extraData?: { reson: string }) {
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('changeTicketStatusQMR');
    return buildAdminCallable({ docId: docId, status: status, extraData: extraData });
  }
}
