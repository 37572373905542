import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-select-modal',
  templateUrl: './user-select-modal.component.html',
  styleUrls: ['./user-select-modal.component.scss'],
})
export class UserSelectModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
