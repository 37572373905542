/* eslint-disable object-shorthand */
// Core+
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';
import { Router } from '@angular/router';

// Firebase/AngularFire
import firebase from 'firebase/compat/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

// Models
import { adminBuilder, User, userTypes } from '../../models/user.model';

// Operations
import { leftJoinDocument } from 'src/app/operations/collection-join';

import { ParentService } from '../parent.service';

import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends ParentService {
  user$: Observable<any>;
  constructor(
    private afStore: AngularFirestore,
    private ngFireAuth: AngularFireAuth,
    private router: Router,
    private functions: AngularFireFunctions
  ) {
    super();
    this.loadUserRelatedDocs();
  }
  loadUserRelatedDocs() {
    return this.user$ = this.ngFireAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afStore
            .doc<User>(`users/${user.uid}`)
            .valueChanges()
            .pipe(
              // Prep user for collection joins (wrap user object in an array)
              map((user) => [user]),
              leftJoinDocument(this.afStore, 'qmrCart', 'qmr-carts', 'qmrCart'),
              // Add linked docs to user doc
              // leftJoinDocument(this.afStore, 'myStore', 'locations', 'myStore'),
              // leftJoinDocument(this.afStore, 'cart', 'carts', 'cart'),
              // Convert user doc back to format needed (single object)
              map((userArr) => userArr[0]),
              // Sets linked doc properties to a blank string instead of null if there was no doc to pass
              map((user: User) => {
                if (user) {
                  if (!user.myStore || user.myStore === null) {
                    user.myStore = '';
                  }
                  if (user.qmrCart === null) {
                    user.qmrCart = '';
                  }
                  localStorage.setItem('user', JSON.stringify(user));
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  JSON.parse(localStorage.getItem('user')!);
                  return user;
                } else {
                  return null;
                }
              })
            );
        } else {
          localStorage.setItem('user', 'null');
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          JSON.parse(localStorage.getItem('user')!);
          // this.router.navigate(['auth/admin/signin']);
          return of(null);
        }
      })
    );
  }
  get loggedInUser(): User {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const user = JSON.parse(localStorage.getItem('user')!);
    return user;
  }
  async userSignIn() {
    const provider = new firebase.auth.GoogleAuthProvider();
    const credential: firebase.auth.UserCredential =
      await this.ngFireAuth.signInWithPopup(provider);
    // console.log(await credential.user.getIdTokenResult());

    const hasClaim = await credential.user
      .getIdTokenResult()
      .then((token) => token.claims.customer);

    if (!hasClaim) {
      return this.setInitalUserData(
        credential.user,
        credential.user.displayName
      ).then(async (res) => {
        // Signs the user out and prompts for re-signIn if it's their first time signing in or they don't have a proper claim

        if (!hasClaim) {
          this.signOut();
          window.alert('Account created! You can now sign in!');
        }
        return res;
      });
    } else {
      return this.setInitalUserData(credential.user).then(async (res) => {
        // Signs the user out and prompts for re-signIn if it's their first time signing in or they don't have a proper claim

        if (!hasClaim) {
          this.signOut();
          window.alert('Account created! You can now sign in!');
        }
        return res;
      });
    }
  }

  // Login in with email/password (for admin and superAdmin level)
  async adminSignIn(email, password) {
    try {
      const credential = await this.ngFireAuth.signInWithEmailAndPassword(
        email,
        password
      );
      if (
        credential.user.emailVerified &&
        (await credential.user.getIdTokenResult()).claims.admin || 1
      ) {
        this.setInitalUserData(credential.user);
        await (this.loadUserRelatedDocs()).subscribe((user) => {
          if (user) {
            // this.router.navigate(['/admin/dashboard']);
          }
        });
        this.router.navigateByUrl('/admin/dashboard', { replaceUrl: true })
      } else {
        window.alert(`Sign In disallowed, consult an admin!`);
        await this.signOut();
      }
    } catch (err) {
      window.alert(err.message);
    }
  }

  // Register user with email/password (for admin and superAdmin level)
  async registerAdminUser(
    email: string,
    password: string,
    displayName: string,
    userType: userTypes,
    disabled: boolean
  ) {
    // Prep the new user data for the server
    const serverPayload: adminBuilder = {
      userData: {
        email: email,
        userType: userType,
        displayName: displayName,
        disabled: disabled
      },
      password: password,
    };
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('buildAdmin');
    return buildAdminCallable(serverPayload);
  }

  // Recover password
  passwordRecover(passwordResetEmail) {
    this.user$.pipe(take(1)).subscribe((user) => {
      try {
        this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail).then(() => {
          if (user.email === passwordResetEmail) {
            this.signOut();
          }
        });
      } catch (err) {
        window.alert(err.message);
      }
    });
  }

  // Sign-out
  signOut() {
    return this.ngFireAuth.signOut().then(() => {
      this.router.navigate(['/auth/admin/signin']);
      setTimeout(() => {
        localStorage.removeItem('user');
      }, 300);
    });
  }

  // Store user in firestore
  private async setInitalUserData(user: firebase.User, displayName?: string) {
    const userData: User = {
      uid: user.uid,
      email: user.email,
      emailVerified: true,
      disabled: false
    };
    if (displayName) {
      userData.displayName = displayName;
    }

    // Send the data-set to the cloud function
    // This will determine if the data changes passed are allowed before setting them
    const setUserDataCallable = this.functions.httpsCallable('setUserDataQ');
    return setUserDataCallable(userData).subscribe((res) => res);
  }

  public async isLoggedIn() {
    return !!await this.ngFireAuth.authState.pipe(first()).toPromise();
  }

  // Change a portion of a user's data in Firestore
  async appendUserData(user: User) {
    // Send the submitted data-set to the cloud function
    // This will determine if the data changes passed are allowed before setting them
    const setUserDataCallable = this.functions.httpsCallable('setUserDataQ');
    return setUserDataCallable(user);
  }

  grantSuperAdmin(email: string) {
    const grantSuperAdminRoleCallable =
      this.functions.httpsCallable('addSuperAdmin');
    return grantSuperAdminRoleCallable(email);
  }
  grantAdmin(email: string) {
    const grantAdminRoleCallable = this.functions.httpsCallable('addAdmin');
    return grantAdminRoleCallable(email);
  }

  revokeSuperAdmin(email: string) {
    const removeSuperAdminRoleCallable =
      this.functions.httpsCallable('removeSuperAdmin');
    return removeSuperAdminRoleCallable(email);
  }
  revokeAdmin(email: string) {
    const removeAdminRoleCallable = this.functions.httpsCallable('removeAdmin');
    return removeAdminRoleCallable(email);
  }

  deleteAdmin(email: string) {
    const deleteAccountRoleCallable =
      this.functions.httpsCallable('deleteAccount');
    return deleteAccountRoleCallable(email);
  }

  // This function likely isn't needed anymore.
  // Once proper UI is built, delete this if it ends up not being needed
  // // Email verification when new user register (for admin and superAdmin level)
  // async sendVerificationMail() {
  //   try {
  //     await (await this.ngFireAuth.currentUser).sendEmailVerification();
  //   } catch (err) {
  //     window.alert(err.message);
  //   }
  // }

  // --- Examples for calling each function and required imports below :) ---

  // // Services
  // import { AuthService } from '../services/auth/auth.service';

  // // Models
  // import { User, userTypes } from '../models/user.model';

  // private email = 'zac@quickmobilerepair.com';
  //   private password = 'NULL';
  //   constructor(private authService: AuthService) {}

  //   signInAdmin() {
  //     try {
  //       this.authService.adminSignIn(this.email, this.password);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  //   signInCustomer() {
  //     try {
  //       this.authService
  //         .userSignIn()
  //         .then((res$) => res$.subscribe((res) => console.log(res)));
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  //   checkAuthStatus() {
  //     this.authService.user$.subscribe((user: User) => {
  //       console.info(user);
  //     });
  //   }

  //   signOut() {
  //     try {
  //       this.authService.signOut();
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  //   makeSA() {
  //     this.authService
  //       .grantSuperAdmin(`info@quickmobilerepair.com`)
  //       .subscribe((res) => console.log(res));
  //   }

  //   makeA() {
  //     this.authService
  //       .grantAdmin(`info@quickmobilerepair.com`)
  //       .subscribe((res) => console.log(res));
  //   }

  //   revokeSA() {
  //     this.authService
  //       .revokeSuperAdmin(`info@quickmobilerepair.com`)
  //       .subscribe((res) => console.log(res));
  //   }
  //   revokeA() {
  //     this.authService
  //       .revokeAdmin(`info@quickmobilerepair.com`)
  //       .subscribe((res) => console.log(res));
  //   }

  //   deleteAccount() {
  //     this.authService
  //       .deleteAdmin(`zac@ingoglia.com`)
  //       .subscribe((res) => console.log(res));
  //   }

  nuEmail = 'zac@ingoglia.com';
  nuPassword = 'test123';
  nuDisplayN = 'Test User';
  nuUserType = userTypes.superAdmin;

  createAdmin() {
    this.registerAdminUser(
      this.nuEmail,
      this.nuPassword,
      this.nuDisplayN,
      this.nuUserType,
      false
    )
      .then((res$) => res$.subscribe((res) => console.log(res)));
  }
}
