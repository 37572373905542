import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
export const userType = ['guest', 'user', 'admin', 'superadmin'][3];
export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  userType = userType;
  public screenWidth = window.innerWidth;
  screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';

  constructor(public authService: AuthService, public router: Router) {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit() {
    window.onresize = () => {
      this.screenSize = window.innerWidth <= 640 ? 'mobile' : 'desktop';
    };
  }
}
