/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { Store } from '../models/store.model';
import { ParentService } from './parent.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends ParentService {

  constructor(
    private afStore: AngularFirestore,
    private functions: AngularFireFunctions,
  ) {
    super();
  }

  allStoreDocs$(filters?): Observable<Store[]> {
    return this.afStore
      .collection<Store>('stores', ref => {
        let storeRef: any = ref;
        if (filters && filters.type) {
          storeRef = ref.where('type', '==', filters.type);
        }
        if (filters && filters.hasOwnProperty('active')) {
          storeRef = ref.where('active', '==', filters.active);
        }
        // if (filters && filters.hasOwnProperty('storeIds') && filters.storeIds.length > 0 ) {
        //   storeRef = ref.where('docId', 'in', filters.storeIds);
        // }
        return storeRef;
      })
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          if (filters && filters.hasOwnProperty('storeIds') && filters.storeIds.length > 0) {
            // storeRef = ref.where('docId', 'in', filters.storeIds);
            changes = changes.filter(change => {
              return filters.storeIds.indexOf(change.payload.doc.data().docId) > -1;
            })
          }
          // This will return an observable of an Array of categories
          const stores = changes.map((change) => {
            const store: Store = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return store;
          });
          return stores;
        })
      );
  }

  async create(postValues) {
    // Prep the new user data for the server
    const serverPayload: Store = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('createStore');
    return buildAdminCallable(serverPayload);
  }

  async update(postValues) {
    // Prep the new user data for the server
    const serverPayload: Store = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('updateStore');
    return buildAdminCallable(serverPayload);
  }
  getCollection$(storeId): Observable<Store> {
    return this.afStore
      .collection<Store>('stores').doc(`${storeId}`).valueChanges();
  }
  slackUsers() {
    // Prep the new user data for the server
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('slackUsers');
    return buildAdminCallable({});
  }

}
