import { Injectable } from '@angular/core';

import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import firebase from 'firebase/compat/app';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ParentService {
  public loading;
  public loadingController: LoadingController;
  db = firebase.firestore();

  constructor() {
    // this.intializeEmulator();
    this.loadingController = new LoadingController();
  }
   async initilaLoader(duration = 0, message?: string) {
    let message2 = 'Please wait...';
    if (message) {
      message2 = message;
    }
    const options: any = {
      message: message2,
      backdropDismiss: false
    };
    if (duration > 0) {
      options.duration = duration;
    }
    this.loading = await this.loadingController.create(options);
    this.loading.present();
  }

  async dismissLoading() {
    if (this.loading) {
      await this.loading.dismiss();
    }
  }
  async dismissLoadingByRef(loaderRef) {
    if (loaderRef) {
      await loaderRef.dismiss();
    }
  }

  async intializeLoaderWithCustomMessage(title) {
    const options: any = {
      message: title,
      backdropDismiss: false,
      spinner: 'circles',
    };
    this.loading = await this.loadingController.create(options);
    this.loading.present();
    return this.loading;
  }

  intializeEmulator() {
    if (location.hostname === 'localhost') {
      this.db.settings({
        host: 'localhost:8102',  // port number for the firestore
        ssl: false
      });
      console.log('Firestore Emulator connected');
    }
  }
}
