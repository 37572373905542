/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { combineLatest, Observable, Observer, of, throwError } from 'rxjs';
import { Region } from '../models/region.model';
import { ParentService } from './parent.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Store } from '../models/store.model';
import { Job, JobStatus } from '../models/job.model';
import { AuthService } from './auth/auth.service';
import { Shipping, ShippingStatus } from '../models/shipping.model';
import { Note } from '../models/note.model';
import { Transaction } from '../models/transaction.model';
import { leftJoin, leftJoinDocument } from 'src/app/operations/collection-join';
import { Category, Product } from '../models/catalog.model';
import { User, userTypes } from '../models/user.model';
import { Series } from '../models/series.model';
import { catchError, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  constructor(
    private afStore: AngularFirestore,
    private functions: AngularFireFunctions,
    private authService: AuthService
  ) {
  }
  get allCategoryDocs$(): Observable<Category[]> {
    return this.authService.user$.pipe(
      mergeMap((user: User) => {
        // For Customers
        if (user && user.userType === userTypes.storeUser) {
          return this.afStore
            .collection<Category>('qmr-categories', (ref) => {
              let catQuery: any = ref;
              catQuery = catQuery.where('active', '==', 1);
              catQuery = catQuery.where('regionIds', 'array-contains-any', user.regionIds);
              catQuery = catQuery.orderBy('displayOrder', 'asc');
              return catQuery;
            }
            )
            .snapshotChanges()
            .pipe(
              // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
              map((changes) => {
                // This will return an observable of an Array of categories
                const categories = changes.map((change) => {
                  const category: Category = {
                    ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
                  };
                  return category;
                });
                return categories;
              }),
              // Sort the results
              // map((categories) => categories.sort((a, b) => a.order - b.order))
            );
          // For Admins
        } else {
          return this.afStore
            .collection<Category>('qmr-categories', (ref) => {
              return ref.orderBy('displayOrder', 'asc');
            })
            .snapshotChanges()
            .pipe(
              // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
              map((changes) => {
                // This will return an observable of an Array of categories
                const categories = changes.map((change) => {
                  const category: Category = {
                    ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
                  };
                  return category;
                });
                return categories;
              }),
              // Sort the results
              // map((categories) => categories.sort((a, b) => a.order - b.order))
            );
        }
      })
    );
  }
  getCategory$(catId): Observable<Category> {
    const job = this.afStore
      .collection<Category>('qmr-categories').doc(`${catId}`).valueChanges().pipe();
    return job;
  }

  getProduct$(productId): Observable<Product> {
    const product = this.afStore
      .collection<Product>('qmr-products').doc(`${productId}`).valueChanges().pipe();
    return product;
  }

  productDocs$(filters): Observable<Product[]> {
    return this.afStore
      .collection<Product>('qmr-products', (ref) => {
        let prQuery: any = ref;
        if (filters.catId) {
          prQuery = prQuery.where('catId', '==', filters.catId)
        }
        if (filters.regionIds) {
          prQuery = prQuery.where('regionIds', 'array-contains-any', filters.regionIds)
        }
        if (filters.active) {
          prQuery = prQuery.where('active', '==', filters.active)
        }
        prQuery = prQuery.orderBy('displayOrder', 'asc')
        return prQuery;
      })
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes.map((change) => {
            const product: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return product;
          });
          return products;
        }),
        // Sort results by order
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  seriesDocs$(categoryId): Observable<Series[]> {
    const series: Series[] = [];
    return new Observable((observer: Observer<Series[]>) =>
      this.afStore
        .collection('productSeries', (ref) =>
          ref.where('category', '==', categoryId)).get().subscribe(data => {
            for (const s of data.docs) {
              series.push(s.data() as Series);
            }
            observer.next(series);
            observer.complete();
          })
    );
  }

  get allProductDocs$(): Observable<Product[]> {
    return this.afStore
      .collection<Product>('products')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const products = changes.map((change) => {
            const product: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return product;
          });
          return products;
        }),
        // Sort results alphabetically
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  get allSeriesDocs$(): Observable<Product[]> {
    return this.afStore
      .collection<Product>('productSeries')
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((changes) => {
          // This will return an observable of an Array of categories
          const seriesList = changes.map((change) => {
            const series: Product = {
              ...change.payload.doc.data(), // Adds properties to the object for any properties of the data object
            };
            return series;
          });
          return seriesList;
        }),
        // Sort results alphabetically
        map((products) => products.sort((a, b) => a.order - b.order))
      );
  }

  // Return a specific product document
  productDoc$(docId: string): Observable<Product> {
    return this.afStore
      .collection<Product>('qmr-products')
      .doc(docId)
      .snapshotChanges()
      .pipe(
        // Passes the Observable to RxJS functions. https://rxjs-dev.firebaseapp.com/api and https://www.learnrxjs.io/
        map((change) => {
          const product: Product = {
            ...change.payload.data(), // Adds properties to the object for any properties of the data object
          };

          if (!change.payload.exists) {
            // eslint-disable-next-line no-throw-literal
            throw {
              code: 1,
              message: `The requested doc doesn't exist`,
            };
          }

          return product;
        }),

        catchError((err) => throwError({
          code: err.code ? err.code : 2,
          message: err.message
            ? err.message
            : `Doc doesn't exist or the current user doesn't have permission to access it`,
        }))
      );
  }

  /**
   * Save Product SeriesModel to Dataabse
   *
   * @param title - Title of the series
   * @param category - Category object of the series
   * @param products - Products array
   */
  saveProductSeries(title: string, category: Category, products: string[]) {
    const series = this.afStore.collection('productSeries').add({
      title,
      categoryName: category.title,
      category: category.docId,
      products
    });

    series.then(seriesRef => {
      seriesRef.set({
        docId: seriesRef.id
      }, { merge: true });
    });

  }

  async updateCategory(categories: Category[]) {
    for (const item of categories) {
      await this.afStore.collection('qmr-categories').doc(item.docId).update(item);
    }
  }
  async updateProductItems(categories: Product[]) {
    for (const item of categories) {
      await this.afStore.collection('qmr-products').doc(item.docId).update(item);
    }
  }

  async updateOneSeries(id: string, title: string, category: Category, products: string[]) {
    const series = this.afStore.collection('productSeries').doc(id);
    await series.update({
      title,
      categoryName: category.title,
      category: category.docId,
      products
    });
  }

  async deleteSeries(series: Series) {
    await this.afStore.collection('productSeries').doc(series.docId).delete();
  }
  async create(postValues) {
    // Prep the new user data for the server
    const serverPayload: Category = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('createCategoryQMR');
    return buildAdminCallable(serverPayload);
  }
  async updatecategory(postValues) {
    // Prep the new user data for the server
    const serverPayload: Category = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('updateCategoryQMR');
    return buildAdminCallable(serverPayload);
  }
  async createProduct(postValues) {
    // Prep the new user data for the server
    const serverPayload: Product = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('createProductQMR');
    return buildAdminCallable(serverPayload);
  }
  async updateProduct(postValues) {
    // Prep the new user data for the server
    const serverPayload: any = postValues;
    // Create the user on the server
    const buildAdminCallable = this.functions.httpsCallable('updateProductQMR');
    return buildAdminCallable(serverPayload);
  }
}
